import React, { useEffect, useLayoutEffect, useState } from "react";
//import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import LoadingSpinner from "../../components/loading-spinner"
import { Link } from "gatsby"

export default function CalculatorFooter({ request_id }) {

  function submitContactForm(form_type) {

      //HIDE BUTTON AND SHOW SPINNER
      document.getElementById("next-step-button").className = "hidden"
      document.getElementById("spinner-form").hidden = false;

      //GET ALL ORDERS IDS
      var email = document.getElementById("lead_email").value;
      var data = new FormData();
      data.append("request_id", request_id);
      data.append("form_type", form_type);
      data.append("email", email);
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.checksolarcost.com/v1/submitContactForm");
      xhr.send(data);
      xhr.onreadystatechange = function() {

        if (xhr.readyState === XMLHttpRequest.DONE) {

            //READ DATA
            var response = xhr.responseText;
            var response_obj = JSON.parse(response);
            //alert(request_id + "Hello! I am an alert box!!" + response_obj.message);
            if ( response_obj.message == "form_successfully_submitted") {

              document.getElementById("calculator-receive-estimate-limited-section").hidden = true;
              //document.getElementById("spinner-form").hidden = true;
              document.getElementById("calculator-estimate-sent").hidden = false;

              //REDIRECT TO GET STARTED (NEW TAB)
              //var feasibility_link = "/get-started/" + "?request_id=" + request_id
              //var feasibility_link ="/design-builder?request_id=" + request_id
              var feasibility_link ="/get-started"

              //window.location.replace(feasibility_link, '_blank');
            
              //setTimeout(function() { window.open(feasibility_link, '_blank').focus(); },3000); /* Using anonymous functions  */
              setTimeout(function() { window.location.replace(feasibility_link); },3000); /* Using anonymous functions  */

            } else {
              document.getElementById("calculator-receive-estimate-limited-section").hidden = true;
              document.getElementById("spinner-form").hidden = true;
              document.getElementById("calculator-estimate-sent-error").hidden = true;
            }
          
            //document.getElementById("calculator-create-account-section").hidden=true;
            //document.getElementById("calculator-receive-estimate-section").hidden=true;
            //document.getElementById("calculator-contact-mortgage-specialist-section").hidden=true;

            //if (form_type == "mortgage_consultation") {
            //    document.getElementById("calculator-form-submitted").hidden=false;   
            //}

            //if (form_type == "estimate_request") {
            //    document.getElementById("calculator-estimate-sent").hidden=false;  
            //}
        }
      }
  }

  function checkContactForm() {
    var email = document.getElementById("lead_email").value;
    if (email.includes("@") == true && email.includes(".") == true) { 
      document.getElementById("next-step-button-disabled").className = "hidden";
      document.getElementById("next-step-button").className = "";
    } else {
      document.getElementById("next-step-button-disabled").className = "";
      document.getElementById("next-step-button").className = "hidden";
    }
  }

  return (

    <>
    <section id="calculator-receive-estimate-limited-section" hidden>
      <div  className="text-center p-6">
  
          <h2 className="text-center font-light tracking-wide px-20 md:px-6">
            Send<span className="font-bradford"> My </span>Estimate
          </h2>
          <div
            className="w-full mx-auto mt-6 px-6"
            style={{ maxWidth: "530px" }}
          >
            <div className="w-full my-1">
              <label htmlFor="lead_email" className="hidden">
                Email
              </label>
              <input
                //onChange={e => handleUserInputChange(e.target)}
                type="email"
                className="p-2 my-4 bg-transparent border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none"
                id="lead_email"
                name="lead_email"
                placeholder="Email address"
                autoComplete="off"
                onChange={checkContactForm}
                required
              />
            </div>
          </div>
          <div className="flex justify-center pb-10">
            <div id="next-step-button-disabled" className="">
              <button
                className={`relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none`}
                disabled
              >Next Step
              </button>
            </div>
            <div id="next-step-button" className="hidden">
              <button
                onClick={() => submitContactForm("estimate_request")}
                className={`relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none`}
              >Next Step
              </button>
            </div>
          </div>
          <div id="spinner-form" className="relative w-full text-center overflow-hidden h-auto" hidden>
              <LoadingSpinner />
          </div>
          <div style = {{border: "20px solid transparent"}}>
            <small className="font-light tracking-wide text-gray-400">
              The information you provide on this form will be used in accordance
              with our{" "}
              <Link className="underline" to="/privacy">
                privacy policy.
              </Link>{" "}
            </small>

            <div style = {{border: "20px solid transparent"}}>
              <small className="font-light tracking-wide text-gray-400 text-center">
                  * Certain California household incomes qualify for a $40,000 grant, to add an Accessory 
                  Dwelling Unit (ADU) to a real estate property. Learn more at: 
                  <a href="https://www.calhfa.ca.gov/homeownership/limits/income/income.pdf" target="blank"> https://www.calhfa.ca.gov </a> 
                  Disclaimer: "Use our payment calculator to estimate your interest rate and monthly payment amount. 
                  Actual loan and payment information will be determined by your lender. Note: Information and interactive 
                  calculators are made available as self-help tools for your independent use and are 
                  intended for educational purposes only. Any results are estimates and we do not guarantee 
                  their applicability or accuracy to your specific circumstances."
              </small>
            </div>
          </div>
      </div>
    </section>

    <section id="calculator-estimate-sent" hidden>
      <div className="text-center p-6">
          <h2 className="text-center font-light tracking-wide px-20 md:px-6">
            Estimate <span className="font-bradford"> successfully </span> sent.
          </h2>
          <br></br>
          <h4 className="text-center font-light tracking-wide px-20 md:px-6">
          A Get Started form will be open in a moment...
          </h4>
          
          <br></br>
          <LoadingSpinner />
          <div style = {{border: "20px solid transparent"}}>
            <small className="font-light tracking-wide text-gray-400">
              The information you provide on this form will be used in accordance
              with our{" "}
              <Link className="underline" to="/privacy">
                privacy policy.
              </Link>{" "}
            </small>
          </div>
          <div style = {{border: "20px solid transparent"}}>
            <small className="font-light tracking-wide text-gray-400 text-center">
                * Certain California household incomes qualify for a $40,000 grant, to add an Accessory 
                Dwelling Unit (ADU) to a real estate property. Learn more at: 
                <a href="https://www.calhfa.ca.gov/homeownership/limits/income/income.pdf" target="blank"> https://www.calhfa.ca.gov/homeownership/limits/income/income.pdf </a> 
                Disclaimer: "Use our payment calculator to estimate your interest rate and monthly payment amount. 
                Actual loan and payment information will be determined by your lender. Note: Information and interactive 
                calculators are made available as self-help tools for your independent use and are 
                intended for educational purposes only. Any results are estimates and we do not guarantee 
                their applicability or accuracy to your specific circumstances."
            </small>
          </div>
      </div>
    </section>

    <section id="calculator-estimate-sent-error" hidden>
      <div className="text-center p-6">
          <h5 className="text-center font-light tracking-wide px-20 md:px-6">
            Internal Error! Please try again later. Sorry for inconvenience.
          </h5>
      </div>
    </section>

  </>
  )
}
